
import { telerecoveryApiUrl } from "../../config/microservicesUrls"
import { axiosGet, axiosPost } from "../../helpers/microserviceAPIs";

const api_url = telerecoveryApiUrl();

export async function trApiFindOpenRooms(jwt, participant_id) {
  return await axiosGet(jwt, `${api_url}/api/video/ListOpened?caseUserId=${participant_id}`)
}
export async function trApiFindParticipantsInRoom(jwt, rmName) {
  return await axiosGet(jwt, `${api_url}/api/video/participants?roomName=${rmName}`)
}

export async function trApiGetTwilioVideoToken(jwt, body) {
  return await axiosPost(jwt, `${api_url}/api/video/token`, body)
}

//Chat

export async function trApiSendChatMessageToStaff(jwt, smsBody) {
  return await axiosPost(jwt, `${api_url}/Chat/ParticipantToPeerMessage`, smsBody)
}

export async function trApiGetChatHistory(jwt, convSid) {
  return await axiosGet(jwt, `${api_url}/Chat/ChatHistory?convSid=${convSid}`)
}

export async function trApiGetChatConversationSid(jwt, peerId, caseId, orgId) {
  return await axiosGet(jwt, `${api_url}/Chat/GetConversations?peerId=${peerId}&caseId=${caseId}&orgId=${orgId}`)
}

export async function trApiChatConvSetRead(jwt, data) {
  return await axiosPost(jwt, `${api_url}/Chat/SetRead`, data)
}