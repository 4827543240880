import React, { useEffect, useContext, useState } from "react"
import { LoaderMedium, GET_STAFF_NAMES, findName, getTimeAgo, staffNameInitials } from "../Utils"
import { UserContext } from "../../../context/userContext"
import { useQuery } from "@apollo/client"
import trcss from "../../../styles/Telerecovery.module.css"
import ChatComponent from "./ChatComponent"
import { trApiGetChatConversationSid, trApiChatConvSetRead } from "../telerecoveryApi"
import { sortListByDate } from "../Utils"

const TRChatMessagesIndex = ({ staffId, staffName, selectedConvStaffId, changeSelectedConv }) => {
  const user = useContext(UserContext)
  const [chatConvs, setChatConvs] = useState(null)
  const [selChatConvSid, setSelChatConvSid] = useState(null)
  const [loading, setLoading] = useState(false)
  const noAssigedStaff = staffName?.full_name.includes("Unassigned")

  const { refetch: refetchStaffNames } = useQuery(GET_STAFF_NAMES, {
    variables: {
      staff_id: user.participant_id,
      organization_id: user.organization_id,
      staff_ids: [user.participant_id]
    },
    skip: true,
  })

  const getStaffsNames = async (staffIds) => {
    if (staffIds.length > 0) {
      const res = await refetchStaffNames({
        staff_id: staffIds[0],
        organization_id: user.organization_id,
        staff_ids: [...new Set(staffIds)]
      })
      if (res.data.getStaffNamesByIds?.data) {
        return JSON.parse(res.data.getStaffNamesByIds.data)
      }
    }
    return {}
  }

  const markConversationRead = (convSid) => {
    trApiChatConvSetRead(user.tokenTelerecovery, { convSid, fromPeer: false })
      .then((resp) => {
        const updatedList = chatConvs.map((conv) => {
          if (conv.sid === convSid) {
            return { ...conv, participantContainsUnread: false }
          }
          return conv
        })
        setChatConvs(updatedList)
      })
  }

  useEffect(() => {
    if (chatConvs && chatConvs.length > 1 && selectedConvStaffId) {
      const index = chatConvs.findIndex((conv) => conv.staffId === selectedConvStaffId)
      if (index !== -1) {
        setSelChatConvSid({ ...chatConvs[index], index })
      }
    }
  }, [selectedConvStaffId, chatConvs])

  const selectConversation = (conv, index) => {
    setSelChatConvSid({ ...conv, index })
    markConversationRead(conv.sid)
    changeSelectedConv(conv.staffId)
  }

  useEffect(() => {
    if (!noAssigedStaff) {
      setLoading(true)
      trApiGetChatConversationSid(user.tokenTelerecovery, staffId, user.participant_id, user.organization_id)
        .then(async (response) => {
          let _chatConvs = response
          const names = await getStaffsNames(_chatConvs.map((conv) => conv.staffId))
          _chatConvs = _chatConvs.map((conv) => {
            conv["staffName"] = findName(conv.staffId, names)
            conv.lastMessageAt = conv.lastMessageAt ? conv.lastMessageAt + 'Z' : conv.lastMessageAt
            return conv
          })
          sortListByDate(_chatConvs, "lastMessageAt")
          setChatConvs(_chatConvs)
          if (_chatConvs.length > 0) {
            selectConversation(_chatConvs[0], 0)
          }
        },
          (err) => console.log(err)
        )
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [user.participant_id])

  if (loading) {
    return <LoaderMedium></LoaderMedium>
  }

  const updateConvList = (newMsg) => {
    const updatedChatConvs = chatConvs.map((conv, index) => {
      if (conv.staffId === newMsg.fromId) {
        return {
          ...conv,
          lastMessageAt: newMsg.dateUpdated,
          participantContainsUnread: (selChatConvSid.staffId !== newMsg.fromId) ? true : conv.participantContainsUnread
        }
      } else if (selChatConvSid.sid === conv.sid && newMsg.fromId === user.participant_id) {
        return {
          ...conv,
          lastMessageAt: newMsg.dateUpdated
        }
      }
      return conv;
    });
    sortListByDate(updatedChatConvs, "lastMessageAt")
    setChatConvs(updatedChatConvs);
  }

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {chatConvs != null && chatConvs.length > 1 &&
        <div className={trcss.chatConvMenu}>
          {chatConvs.map((conv, index) => {
            return (
              <button
                style={{ width: "100%", position: "relative" }}
                key={`${conv.sid}_${index}`}
                onClick={() => selectConversation(conv, index)}
              >
                <div className={`${trcss.chatConvElement} ${selChatConvSid && selChatConvSid.index === index && trcss.chatConvElementSelected}`}>
                  <span className={trcss.userInitials}>{staffNameInitials(conv.staffName)}</span>
                  <div>
                    <p>{conv.staffName.full_name}</p>
                    <p style={{ fontSize: "11px" }}>{getTimeAgo(conv.lastMessageAt)}</p>
                  </div>
                </div>
                {conv.participantContainsUnread && <span className={trcss.unread}></span>}
              </button>
            )
          })
          }
        </div>
      }
      {selChatConvSid &&
        <div style={{ backgroundColor: "#E7E0F180", borderRadius: "20px", width: "100%", padding: "15px", maxWidth: "800px" }}>
          <ChatComponent
            chatConvSid={selChatConvSid.sid}
            staffId={selChatConvSid.staffId}
            staffName={selChatConvSid.staffName}
            onNewMessage={updateConvList}
          />
        </div>
      }
      {noAssigedStaff && <p>Currently, there is no staff member assigned to you. Kindly reach out to an administrator for further assistance.</p>}
    </div>
  )
}
export default TRChatMessagesIndex;