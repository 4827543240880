// CONST
import ROUTES from "./routesContants"

// Pages
import * as generalPages from "./page-groups/general_page"
import * as participants from "./page-groups/participant"
import * as user from "./page-groups/user"

const routesDef = [
  {
    path: ROUTES.HOMEPAGE,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.ParticipantProfile />,
  },
  {
    path: ROUTES.DENIED,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <generalPages.PermissionDenied />,
  },
  {
    path: ROUTES.DEMO_PAGE,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <generalPages.DemoPage />,
  },

  {
    path: ROUTES.PARTICIPANT_TELERECOVERY_SERVICES + "/:type",
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.TelerecoveryServices />,
  },

  {
    path: ROUTES.PARTICIPANT_TELERECOVERY_SERVICES + "/:type/:peerId",
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.TelerecoveryServices />,
  },

  {
    path: ROUTES.FORM_PAGE,
    requireAdmin: false,
    leastPrivilege: 6,
    userGroupType: 0,
    element: <generalPages.Form />,
  },
  {
    path: ROUTES.FORM_VIEW_MODE,
    requireAdmin: false,
    leastPrivilege: 100,
    userGroupType: 0,
    element: <generalPages.FormViewMode />,
  },
  {
    path: ROUTES.PARTICIPANT_CUSTOM_DATA_FORMS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.CustomDataForms />,
  },

  {
    path: ROUTES.PARTICIPANT_RECOVERY_CAPITAL,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.RecoveryCapital />,
  },
  {
    path: ROUTES.PARTICIPANT_RECOVERY_PLAN,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.RecoveryPlan />,
  },
  {
    path: ROUTES.PARTICIPANT_RECOVERY_PLAN_VIEW,
    requireAdmin: false,
    leastPrivilege: 100,
    userGroupType: 0,
    element: <participants.RecoveryPlanView />,
  },
  {
    path: ROUTES.PARTICIPANT_ENGAGEMENTS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.Engagements />,
  },
  {
    path: ROUTES.PARTICIPANT_ENGAGED_SERVICES,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.EngagedServices />,
  },
  {
    path: ROUTES.PARTICIPANT_SERVICE_EPISODES,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.ServiceEpisodes />,
  },
  {
    path: ROUTES.PARTICIPANT_REFERRALS_DETAIL,
    requireAdmin: true,
    leastPrivilege: "100",
    element: <participants.ReferralsDetail />,
  },
  {
    path: ROUTES.PARTICIPANT_REFERRALS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.Referrals />,
  },
  {
    path: ROUTES.PARTICIPANT_CUSTOM_FORMS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.CustomsFormsIndex />,
  },
  {
    path: ROUTES.PARTICIPANT_PROFILE,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.ParticipantProfile />,
  },
  {
    path: ROUTES.PARTICIPANT_PROFILE_ACCOUNT_EDIT,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.ParticipantProfileAccountEdit />,
  },
  {
    path: ROUTES.PARTICIPANT_DOCUMENTS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.Documents />,
  },
  {
    path: ROUTES.PARTICIPANT_VOLUNTEER_ACTIVITY_LOG,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.VolunteerActivityLog />,
  },
  {
    path: ROUTES.PARTICIPANT_REAL_D_FORM,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.REALDForm />,
  },
  {
    path: ROUTES.PARTICIPANT_HEP_C_FORM,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.HEPCForm />,
  },
  {
    path: ROUTES.PARTICIPANT_HEP_A_FORM,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.HEPABForm />,
  },
  {
    path: ROUTES.PARTICIPANT_HARM_REDUCTION_ACTIVITY_LOG,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.HarmReductionActivityLog />,
  },
  {
    path: ROUTES.PARTICIPANT_GENERAL_ACTIVITY_LOG,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.GeneralActivityLog />,
  },
  {
    path: ROUTES.PARTICIPANT_CONTACT_LOG,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.ContactLog />,
  },
  {
    path: ROUTES.PARTICIPANT_APPOINTMENTS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.Appointments />,
  },
  {
    path: ROUTES.PARTICIPANT_NOTIFICATIONS,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.Notifications />,
  },
  {
    path: ROUTES.PARTICIPANT_MY_ACCOUNT,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.MyAccount />,
  },
  {
    path: ROUTES.PARTICIPANT_RECOVERY_CAPITAL_ASSESMENT,
    requireAdmin: false,
    leastPrivilege: 100,
    element: <participants.RecoveryCapitalAssesment />,
  },
  {
    path: ROUTES.PROFILE_EDIT,
    requireAdmin: false,
    leastPrivilege: 100,
    userGroupType: 0,
    element: <user.EditProfile />,
  },
]

export default routesDef
