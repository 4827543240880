import React from "react"

const ParticipantsInRoom = ({ participants, participantIdentity }) => {
  return (
    <>
      <h3 style={{ color: "rgb(132, 103, 173)" }}>Participants in Room</h3>
      {participants && participants.length > 0 && (
        <ul style={{ listStyle: "disc"}}>
          {participants.map((participant, i) => {
            return (
              <li style={{ marginright: "30px" }} key={"participant-" + i}>
                 {participant.identity + (participant.identity === participantIdentity ? " (Participant)" : " (Peer)")} 
              </li>
            )
          })}
        </ul>
      )}
      {participants  && participants.length === 0 && <span>No participants in this room</span>}
    </>
  )
}

export default ParticipantsInRoom
