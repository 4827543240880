import React, { useState, useCallback, useContext } from "react";
import Room from "./Room";

import placeholder from "../../../assets/images/VideoCallPlaceholder.PNG";
import { UserContext } from "../../../context/userContext";

import { telerecoveryApiUrl } from "../../../config/microservicesUrls";
import ParticipantsInRoom from "./ParticipantsInRoom";

//SignalR
import { useClientMethod, useHub } from "react-use-signalr";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import SessionTimer from "../SessionTimer";
import { trApiFindOpenRooms, trApiFindParticipantsInRoom, trApiGetTwilioVideoToken } from "../telerecoveryApi";

const microserviceUrl = telerecoveryApiUrl();

const VideoChat = () => {
  const user = useContext(UserContext);

  const [identity, setIdentity] = useState("");
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);
  const [connecting, setConnecting] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [participantsInRoom, setParticipantsInRoom] = useState([]);
  const [staffName, setStaffName] = useState(null);
  const [sessionStartedAt, setSessionStartedAt] = useState();

  const configureParticipantChecking = useCallback(() => {
    findParticipantsInRoom(roomName);
    setInterval(() => {
      findParticipantsInRoom(roomName);
    }, 3000);
  }, [roomName]);

  const findOpenRooms = useCallback(() => {
    console.log(user);
    trApiFindOpenRooms(user.tokenTelerecovery,user.participant_id)
      .then(
        (response) => {
          if (response.length > 0) {
            let rooms = response;
            setRoomName(rooms[0].roomId);
            setStaffName(rooms[0].staffName);
            setSessionStartedAt(rooms[0].sessionStartedAt);

            configureParticipantChecking(rooms[0].roomId);
          }
        },
        (err) => console.log(err)
      );
  }, [user.participant_id, configureParticipantChecking]);

  React.useEffect(() => {
    setIdentity(user.participant_name_first + " " + user.participant_name_last);
    findOpenRooms();
  }, [findOpenRooms, user.participant_name_first, user.participant_name_last]);

  // SignalR
  const [connection, setConnection] = useState(null);
  const { hubConnectionState, error: hubError } = useHub(connection);

  useClientMethod(
    connection,
    "RoomOpen",
    (targetCaseUserId, staffId, sharedRoomId, sharedStaffName) => {
      if (targetCaseUserId === user.participant_id) {
        setRoomName(sharedRoomId);
        configureParticipantChecking(sharedRoomId);
      }
    }
  );

  // SignalR
  React.useEffect(() => {
    if (!connection) {
      setupSignalRConnection();
    }

    // Monitor for SignalR errors
    if (hubError) {
      console.warn(
        "SignalR Warning: ",
        hubError,
        `\nConnection State: ${hubConnectionState}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, hubError]);

  function setupSignalRConnection() {
    // Setup Connection
    const connection = new HubConnectionBuilder()
      .withUrl(microserviceUrl + "/notificationHub")
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    connection?.onclose(async () => {
      connection?.start().catch((error) => {
        console.warn(error);
      });
    });

    setConnection(connection); // Save connection to state
  }

  const findParticipantsInRoom = (rmName) => {
    if (rmName) {
      trApiFindParticipantsInRoom(user.tokenTelerecovery, rmName)
        .then(
          (response) => {
            setParticipantsInRoom(Array.from(response));
          },
          (err) => console.log(err)
        );
    }
  };

  // First i get a video token from the Twilio microservice, then with that token i use the Twilio specific API
  //to connect to the room , and i get a twilio room object as a result, which i set in the state.room
  const joinRoom = () => {
    try {
      setConnecting(true);
      trApiGetTwilioVideoToken(user.tokenTelerecovery, { username: identity })
        .then((response) => {
          let token = response.token;
          setConnecting(false);
          setIsConnected(true);
          setToken(token);
          if (!sessionStartedAt) {
            setSessionStartedAt(new Date());
          }
        });
    } catch (err) {
      setConnecting(false);
    }
  };

  const handleLogout = useCallback((event) => {
    setToken(null);
    setIsConnected(false);
    setSessionStartedAt(null);
  }, []);

  const handleJoinOrLeaveRoom = () => {
    if (isConnected) {
      handleLogout();
    } else {
      joinRoom();
    }
  };

  const roomBtns = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <div style={{ width: "30%", float: "left" }}>
          {participantsInRoom && participantsInRoom.length > 0 && (
            <button className="btn" onClick={() => handleJoinOrLeaveRoom()}>
              {isConnected === false
                ? connecting
                  ? "Connecting..."
                  : "Join Room"
                : "Leave Room"}
            </button>
          )}
        </div>
        <div style={{ float: "left" }}>
          <ParticipantsInRoom
            participants={participantsInRoom}
            participantIdentity={identity}
          ></ParticipantsInRoom>
        </div>
        <div style={{ float: "right" }}>
          {sessionStartedAt && isConnected && (
            <SessionTimer startTime={sessionStartedAt}></SessionTimer>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {token && (
        <Room
          roomName={roomName}
          token={token}
          handleLogout={handleLogout}
          staffName={staffName}
        />
      )}
      {!token && (
        <img
          alt="emptyVideo"
          src={placeholder}
          style={{ width: "700px" }}
        ></img>
      )}
      {roomBtns()}
    </>
  );
};

export default VideoChat;
