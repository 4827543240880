import { useContext, useEffect, useState } from "react"

//Context
import { UserContext } from "../../../context/userContext"

//ServiceUrl components
import ServiceUrl from "./ServiceUrl"

//Styles
import devToolStyles from "../../../styles/developerTools/DevTools.module.css"
import myStyles from "../../../styles/developerTools/DevToolsServiceUrls.module.css"

//Helpers
import { getEnvironmentCode } from "../developerToolsHelpers"
import {
  getURLOverrideValue,
  MicroServices,
  setURLOverrideValue,
} from "./serviceUrlHelpers"

//PrimeReact
import { Button } from "primereact/button"
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"
import {
  appointmentsApiUrl,
  assessmentsApiUrl,
  documentSigningApiUrl,
  notificationsUrl,
  referralsUrl,
  tasksUrl,
  telerecoveryApiUrl,
} from "../../../config/microservicesUrls"

export default function DevToolServiceUrls({
  expandedCode,
  onSetExpandedCode,
  onUpdated,
}) {
  const cardCode = "ServiceUrls"
  const isExpanded = cardCode === expandedCode

  const userCtx = useContext(UserContext)
  const [reloadServices, setReloadServices] = useState(true)
  const [services, setServices] = useState([])

  const hasChanges = services.some((service) => service.newOverride)
  const hasOverrides = services.some(
    (service) => service.activeOverride?.overrideUrl
  )

  useEffect(() => {
    let serviceList = MicroServices.map((service) => {
      return { ...service, newOverride: "" }
    })

    const environmentCode = getEnvironmentCode()
    serviceList = serviceList.map((service) => {
      const envVar = `${service.envVariable}${environmentCode}`

      service.storageKey = envVar
      service.defaultUrl = getDefaultUrl(service.name)
      service.activeOverride = getURLOverrideValue(envVar)

      return service
    })

    setServices(serviceList)
  }, [reloadServices])

  function getDefaultUrl(serviceName) {
    switch (serviceName) {
      case "Appointments":
        return appointmentsApiUrl(true)
      case "Assessments":
        return ""
      case "Document Signing":
        return documentSigningApiUrl(true)
      case "Notifications":
        return notificationsUrl(true)
      case "Referrals":
        return referralsUrl(true)
      case "Tasks":
        return tasksUrl(true)
      case "Telerecovery":
        return telerecoveryApiUrl(true)
      default:
        return "<UNKNOWN>"
    }
  }

  const saveOverrides = (newServices) => {
    const useServices = newServices || services

    useServices.forEach((service) => {
      if (
        service.newOverride === service.defaultUrl &&
        service.activeOverride
      ) {
        setURLOverrideValue(service.storageKey, "")
      } else if (
        service.newOverride &&
        service.newOverride !== service.defaultUrl
      ) {
        setURLOverrideValue(service.storageKey, service.newOverride)
      }
    })

    setReloadServices(!reloadServices)
    onUpdated()

    userCtx.setNotificationInformation({
      message: (
        <>
          URL override state updated. <br />
          It is recommended to refresh your webpage to ensure that new URLs are
          taken into account in all components
        </>
      ),
      life: 10000, //Show the notification for 10s
    })
  }

  const resetAllUrls = () => {
    const newServices = services.map((service) => {
      return {
        ...service,
        newOverride: service.defaultUrl,
        reset: !service.reset,
      }
    })

    setServices(newServices)
    saveOverrides(newServices)
  }

  return (
    <div
      className={`${devToolStyles.cardLayout} ${
        isExpanded ? devToolStyles.cardLayoutExpanded : ""
      } ${
        expandedCode !== "" && expandedCode !== cardCode
          ? devToolStyles.cardLayoutHidden
          : ""
      }`}
      onClick={() => {
        onSetExpandedCode(cardCode)
      }}
    >
      <ConfirmDialog />
      <div className={devToolStyles.cardLayoutTitle}>Service URLS</div>
      <div className={devToolStyles.cardLayoutStatus}>
        {hasOverrides ? (
          <div
            className={`${devToolStyles.activeBadge} ${
              isExpanded ? devToolStyles.expandedBadge : ""
            }`}
          >
            {isExpanded ? "ACTIVE" : ""}
          </div>
        ) : null}
      </div>
      <div className={devToolStyles.cardLayoutDescription}>
        Temporarily override service API URLs
      </div>
      <div
        className={`${devToolStyles.cardLayoutBody} ${myStyles.serviceList}`}
      >
        {services.map((service) => (
          <ServiceUrl
            service={service}
            key={service.name}
            onOverride={(overrideValue) => {
              const newService = { ...service, newOverride: overrideValue }
              const index = services.indexOf(service)

              let newServices = [...services]
              newServices[index] = newService

              setServices(newServices)
            }}
          />
        ))}
      </div>
      <div className={devToolStyles.cardLayoutFooter}>
        <Button
          label="Reset all"
          type="button"
          className={`${devToolStyles.button} ${devToolStyles.noBackground}`}
          onClick={() => {
            confirmDialog({
              message: "Are you sure you want to reset all overrides?",
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              defaultFocus: "accept",
              accept: resetAllUrls,
              reject: undefined,
            })
          }}
          disabled={!hasChanges && !hasOverrides}
        />
        <Button
          label="Save"
          type="button"
          className={`${devToolStyles.button}`}
          onClick={() => {
            saveOverrides()
          }}
          disabled={!hasChanges}
        />
      </div>
    </div>
  )
}
