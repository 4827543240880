import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

const Participant = ({ participant, showTopRight, room, isLocal }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
        track.on("enabled", () => setIsVideoEnabled(true));
        track.on("disabled", () => setIsVideoEnabled(false));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [isVideoEnabled, participant.videoTracks, participant.audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const muteOnOff = () => {
    room.localParticipant.audioTracks.forEach((publication) => {
      if (!isMuted) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });

    setIsMuted(!isMuted);
  };

  const turnCameraOnOff = () => {
    videoTracks.forEach((track) => {
      if (isCameraOff) {
        track.enable();
        console.log("camera on");
      } else {
        track.disable();
        console.log("camera off");
      }
    });

    setIsCameraOff(!isCameraOff);
  };

  return (
    <>
      <div
        className={
          "participant " + (showTopRight === true ? "topRightVideo" : "")
        }
      >
        {isVideoEnabled === true && <video ref={videoRef} autoPlay={true} />}
        {isVideoEnabled === false && (
          <div style={{ backgroundColor: "black", height: "525px" }}></div>
        )}
        <audio ref={audioRef} autoPlay={true} />
      </div>

      {isLocal === true && (
        <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <Button
            icon="pi pi-volume-up"
            className={isMuted ? "trackBtnOff" : ""}
            style={{ marginRight: "10px", zIndex: "9" }}
            onClick={() => muteOnOff()}
          />
          <Button
            icon="pi pi-video"
            className={isCameraOff ? "trackBtnOff" : ""}
            style={{ zIndex: "9" }}
            onClick={() => turnCameraOnOff()}
          />
        </div>
      )}
    </>
  );
};

export default Participant;
