import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import Participant from "./Participant";
import "../../../styles/Telerecovery/VideoParticipantTrack.css";
import { LoaderMedium } from "../../../components/Loaders";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

const Room = ({ roomName, token, staffName, handleLogout }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      //   setRoomRef(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const participantConnected = (participant) => {
    setParticipants((prevParticipants) => [
      ...prevParticipants.filter((p) => p.identity != participant.identity),
      participant,
    ]);
  };

  const participantDisconnected = (participant) => {
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );

    handleLogout();
  };

  const remoteParticipants = participants.map((participant) => (
    <Participant room={room} participant={participant} />
  ));

  return (
    <div className="room" style={{ position: "relative" }}>
      <div className="local-participant">
        {room ? (
          <Participant
            room={room}
            showTopRight={remoteParticipants && remoteParticipants.length > 0}
            participant={room.localParticipant}
            isLocal={true}
          />
        ) : (
          <LoaderMedium />
        )}
      </div>
      <div className="remote-participants" style={{ minHeight: "450px" }}>
        {remoteParticipants}
      </div>
    </div>
  );
};

export default Room;
