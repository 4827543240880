import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"

//graphql
import { useLazyQuery } from "@apollo/client"
import { GET_PARTICIPANT_ORGNIZATIONS } from "./queries"
import { GET_STAFF } from "./notificationsQueries"

//Helpers
import {
  LogDebug,
  GetLogLevelKey,
  LogError,
  LogWarning,
} from "../../helpers/logger"

//SignalR
import { useClientMethod, useHub } from "react-use-signalr"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"

//Notifications modules
import { notificationsUrl } from "../../config/microservicesUrls"
import {
  noteApiCountPost,
  noteApiListMutedPost,
  noteApiMarkAsReadPut,
} from "../notifications/notificationsApi"
import NotificationsToastContent from "../notifications/notificationsToastContent"
import NotificationsForm from "./form/notificationsForm"

//Tasks modules
import TasksForm from "./tasksForm/TasksForm"

// Context
import { UserContext } from "../../context/userContext"

//PrimeReact
import { Badge } from "primereact/badge"
import { Toast } from "primereact/toast"
import { Dialog } from "primereact/dialog"
import { TabMenu } from "primereact/tabmenu"

//styling
import noteStyle from "../../styles/NotificationsForm.module.css"
import { useNavigate } from "react-router-dom"
import ROUTES from "../Routes/routesContants"
import {
  notificationHasTags,
  replaceNotificationTags,
} from "./notificationsTags"

export default function Notifications() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const pid = user.participant_id
  const isDisabledParticipant = [
    "Inactive Discharged",
    "Inactive Referrals",
  ].includes(user.participant_membership_status)

  const organization_id = "All"
  const enterprise_id = "All"

  const [notificationsCount, setNotificationsCount] = useState(null)
  const [mutedNotifications, setMutedNotifications] = useState(null)
  const [errorState, setErrorState] = useState(null)
  const [organizations, setOrganizations] = useState(null)
  const [show, setShow] = useState("")
  const [refreshCount, setRefreshCount] = useState(new Date().getTime())
  const [notificationsActiveKey, setNotificationsActiveKey] =
    useState("notifications")
  const [externalAction, setExternalAction] = useState(undefined)

  //task related state items
  const [tasksActiveKey, setTasksActiveKey] = useState("assigned-tasks")
  const [tasksActiveSubKey, setTasksActiveSubKey] = useState(0)
  const [taskUsers, setTaskUsers] = useState([])
  const [skipTaskUsersRefresh, setSkipTaskUsersRefresh] = useState(false)

  const toast = useRef(null)
  const systemNotificationsFilter = ["Participants", "All"]

  // SignalR
  const isReconnecting = useRef(false)
  const [connection, setConnection] = useState(null)
  const { hubConnectionState, error: hubError } = useHub(connection)
  useClientMethod(connection, "NotificationEvent", (notification) => {
    handleNotificationEvent(notification)
  })

  function setupSignalRConnection() {
    try {
      const userDetail = `[PARTICIPANT] ${user.participant_id} - ${user.participant_name_first} ${user.participant_name_last}`

      //Set isReconnecting to false, because we will be creating a new connection now.
      isReconnecting.current = false

      // Setup Connection
      const connection = new HubConnectionBuilder()
        .withUrl(`${notificationsUrl()}/hubs/notifications?user=${userDetail}`)
        .withAutomaticReconnect()
        .configureLogging(
          GetLogLevelKey() === "debug" ? LogLevel.Debug : LogLevel.Warning
        )
        .build()

      connection.onreconnecting((error) => {
        LogWarning(`Notification hub reconnecting to server`, error)
        isReconnecting.current = true
      })

      connection.onreconnected(() => {
        // Update notification counter
        getNotificationsCount(false)
        isReconnecting.current = false
      })

      connection.onclose((error) => {
        if (isReconnecting.current || error) {
          isReconnecting.current = true
          const errorMessage = `Notifications have been disconnected, please refresh your browser to reconnect`
          setErrorState(errorMessage)
          //If the connection closed due to an error, attempt to reconnect
          LogWarning(
            errorMessage,
            isReconnecting.current
              ? "Unable to reconnect to the Notifications Hub"
              : error
          )
        }
      })

      setConnection(connection) // Save connection to state
    } catch (error) {
      setConnection(undefined)
      LogError(
        "Unable to connect to to Notification Hub, retrying in 10 seconds",
        error
      )
      restartNotificationHub()
    }
  }

  function restartNotificationHub(connection, timeOutPeriod) {
    setTimeout(function () {
      if (connection) {
        connection
          .start()
          .then((e) => {
            setErrorState(null)
            getNotificationsCount(true)
          })
          .catch((error) => {
            LogError("Unable to connect to the Notifications hub", error)
            showError(
              "Unable to connect to the Notifications hub. Please refresh the page."
            )
            restartNotificationHub(connection, 30000) //Retry after 30s
          })
      } else {
        setupSignalRConnection()
      }
    }, timeOutPeriod || 10000) // Restart connection after 10 seconds or as long as requested.
  }

  const [getOrganizations] = useLazyQuery(GET_PARTICIPANT_ORGNIZATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      pid,
    },
  })

  const [getStaff] = useLazyQuery(GET_STAFF, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: undefined,
      organization_id: undefined,
    },
  })

  function notificationToUser(notification) {
    //Check by UserId
    if (notification?.userId) {
      return (
        notification.userId.toString() === pid.toString() ||
        notification.userId.toString() === user.participant_alias
      )
    }
  }

  function systemNotificationToUserGroup(notification) {
    return (
      organizations.some(
        (x) => x.organization_id === notification.groupId.toString()
      ) &&
      notification.systemNotification === true &&
      systemNotificationsFilter.some(
        (filter) => filter === notification.systemNotificationFilter
      )
    )
  }

  function systemNotificationToUserEnterprise(notification) {
    if (user.enterprise_id) {
      return (
        notification.enterpriseId?.toString() ===
          user.enterprise_id.toString() &&
        notification.systemNotification === true &&
        systemNotificationsFilter.some(
          (filter) => filter === notification.systemNotificationFilter
        )
      )
    } else {
      return false
    }
  }

  async function checkNotificationSoundMuted(eventName) {
    try {
      let _mutedNotifications = !mutedNotifications
        ? await getMutedNotifications()
        : mutedNotifications

      if (_mutedNotifications?.mutedEventTypes?.length > 0) {
        const eventType = _mutedNotifications?.mutedEventTypes.find(
          (x) => x.name === eventName
        )

        if (eventType && eventType.sound) {
          return true
        }
      }
    } catch (error) {
      LogError(error)
    }

    return false
  }

  async function checkNotificationMuted(eventName) {
    try {
      let _mutedNotifications = !mutedNotifications
        ? await getMutedNotifications()
        : mutedNotifications

      if (_mutedNotifications?.length > 0) {
        const eventType = _mutedNotifications?.mutedEventTypes.find(
          (x) => x.name === eventName
        )

        if (eventType && eventType.channelSignalR) {
          return true
        }
      }
    } catch (error) {}

    return false
  }

  async function getMutedNotifications() {
    try {
      const res = await noteApiListMutedPost(
        user.tokenNotification,
        organization_id, //user.organization_id,
        pid
      )

      if (res) {
        setMutedNotifications(res)
        return res
      }
    } catch (error) {
      console.error(`Unable to get notification mute settings`, error)
      toast.error((t) => (
        <NotificationsToastContent toast={t} systemNotification>
          Unable to get notification mute settings. See console for details.
        </NotificationsToastContent>
      ))

      return []
    }
  }

  async function findStaff(staff_id) {
    let result = undefined
    const staff = await getStaff({
      variables: {
        staff_id: staff_id,
      },
    })

    if (!!staff.data?.user && staff.data?.user.length > 0) {
      result = staff.data.user[0]
    }

    return result
  }

  async function handleNotificationEvent(notification) {
    LogDebug("Notification Event Received", notification)

    // Filter events to active group and user
    const noteToUser = notificationToUser(notification)
    const sysNotificationToUserGroup =
      systemNotificationToUserGroup(notification)
    const sysNotificationToUserEnterprise =
      systemNotificationToUserEnterprise(notification)

    if (
      noteToUser ||
      sysNotificationToUserGroup ||
      sysNotificationToUserEnterprise
    ) {
      const notificationMuted = await checkNotificationMuted(
        notification.eventType
      )
      if (notificationMuted) LogDebug("Notification Event Muted", notification)

      if (
        (notification.highPriority || notification.showToast) &&
        !notificationMuted
      ) {
        //Check description for system tags
        if (notificationHasTags(notification)) {
          const participantList = [
            {
              participant_id: user.participant_id,
              participant_first_name: user.participant_first_name,
              participant_last_name: user.participant_last_name,
            },
          ]

          notification = await replaceNotificationTags(
            notification,
            participantList,
            undefined,
            undefined,
            undefined,
            findStaff
          )
        }

        const notificationSoundMuted = await checkNotificationSoundMuted(
          notification.eventType
        )

        // Show toast message
        const id = Date.now()
        toast.current.show({
          severity: "info",
          detail: id,
          closable: false,
          life:
            notification.primaryActionUrl || notification.secondaryActionUrl
              ? 30000
              : 15000,
          content: (
            <NotificationsToastContent
              toast={toast}
              toastId={id}
              notificationId={notification.id}
              notification={notification}
              systemNotification={notification.systemNotification}
              title={notification.title}
              description={notification.description}
              primaryActionLabel={notification.primaryActionLabel}
              primaryActionUrl={notification.primaryActionUrl}
              secondaryActionLabel={notification.secondaryActionLabel}
              secondaryActionUrl={notification.secondaryActionUrl}
              onHandleActionURL={handleActionURL}
              markNotificationRead={markAsRead}
              isDisabledParticipant={isDisabledParticipant}
              muteSound={notificationSoundMuted}
            >
              <div>
                <div className="mb-2">
                  <b>{notification.title}</b>
                </div>
                {notification.description}
              </div>
            </NotificationsToastContent>
          ),
        })
      }

      // Update notification counter
      getNotificationsCount()
    }
  }

  const handleShowTasks = (eventKey, eventSubKey) => {
    if (eventKey) {
      setTimeout(() => {
        setTasksActiveKey(eventKey || "assigned-tasks")
        setTasksActiveSubKey(eventSubKey || 0)
        LogDebug("setTasksActiveKey", eventKey)
      }, 500)
    }
    setShow("tasks")
  }

  const handleShowSignatureRequests = (nid) => {
    navigate(ROUTES.PARTICIPANT_DOCUMENTS, {
      replace: true,
      state: { overrideDefaultFilter: "awaiting_signature" },
    })
    setShow("")
  }

  const handleShowNotifications = (eventKey) => {
    setNotificationsActiveKey(eventKey || "notifications")
    setShow("notifications")
  }

  const handleActionURL = (actionUrl, notificationId, notification) => {
    switch (actionUrl) {
      case "#ShowNotifications":
        setShow("notifications")
        break
      case "#ShowNotificationSettings":
        setNotificationsActiveKey("mute")
        setShow("notifications")
        break
      case "#assigned-tasks":
        handleShowTasks("assigned-tasks")
        if (notificationId) markAsRead(notificationId)
        break

      case "#created-tasks-pending":
        handleShowTasks("created-tasks", 0)
        if (notificationId) markAsRead(notificationId)
        break

      case "#created-tasks-completed":
        handleShowTasks("created-tasks", 1)
        if (notificationId) markAsRead(notificationId)
        break

      case "#signature-request-sent":
      case "#signature-request-signed":
      case "#signature-request-all-signed":
      case "#signature-request-declined":
      case "#signature-request-canceled":
        // if (notificationId) MarkAsRead(notificationId);
        handleShowSignatureRequests(notificationId)
        break

      case "#notifications":
      case "#go-to-chatconv":
        handleIncomingChatMessage(notification)
        break
      case "#sys-notifications":
      case "#mute":
        handleShowNotifications(actionUrl?.replace("#", ""))
        break
      case "#GoToReferral":
        handleReferralNavigation(notification)
        setShow("")
        break
      default:
        if (actionUrl.includes("http")) {
          window.location.href = actionUrl
        } else {
          window.location = `${window.location.protocol}//${window.location.host}/${actionUrl}`
        }
    }
  }

  if (!!externalAction) {
    const action = externalAction
    setExternalAction(undefined)
    if (!action.actionUrl) {
      LogError("Unable to handle action, no action specified")
    } else {
      handleActionURL(action.actionUrl, action.payload, undefined)
    }
    user.setNotificationAction(undefined)
  }

  async function handleIncomingChatMessage(notification) {
    return navigate(
      `${ROUTES.PARTICIPANT_TELERECOVERY_SERVICES}/chat/${notification.staffId}`
    )
  }

  /* Referrals specific */
  const handleReferralNavigation = (notification) => {
    if (!!notification.primaryActionPayload) {
      const payload = notification.primaryActionPayload
        .replaceAll('"', "")
        .split("\\u0026")[0]
      const editReferralLink = ROUTES.PARTICIPANT_REFERRALS_DETAIL
      const path = `${editReferralLink.substring(
        0,
        editReferralLink.lastIndexOf("/")
      )}/${payload}`

      navigate(path, {
        replace: true,
      })
    }
  }

  const showError = useCallback(
    (message) => {
      const id = Date.now()
      if (toast.current) {
        toast.current.show({
          severity: "error",
          detail: id,
          closable: false,
          sticky: isReconnecting.current,
          content: (
            <NotificationsToastContent
              toast={toast}
              systemNotification
              isError
              toastId={id}
              description={message || errorState}
              isInfo={true}
            />
          ),
        })
      }
    },
    [errorState]
  )

  const showInfo = (message) => {
    const id = Date.now()
    //If the received message is a string, or does not have a message field, process it normally
    if (
      typeof message === "string" ||
      message instanceof String ||
      !message.message
    ) {
      toast.current.show({
        severity: "info",
        detail: id,
        closable: false,
        content: (
          <NotificationsToastContent
            toast={toast}
            systemNotification
            toastId={id}
            description={message}
            isInfo={true}
          />
        ),
      })
    } else {
      const messageValue = message.message
      const toastConfig = { ...message, message: undefined }

      toast.current.show({
        ...toastConfig,
        severity: "info",
        detail: id,
        closable: false,
        content: (
          <NotificationsToastContent
            toast={toast}
            systemNotification
            toastId={id}
            description={messageValue}
            isInfo={true}
          />
        ),
      })
    }
  }

  const handleButtonClick = () => {
    if (errorState) {
      showError()
    } else {
      handleShowNotifications()
    }
  }

  async function markAsRead(notificationId) {
    if (notificationId) {
      try {
        await noteApiMarkAsReadPut(user.tokenNotification, notificationId)
        getNotificationsCount()
        LogDebug(`Notification marked as read (ID: ${notificationId})`)
      } catch (error) {
        console.error(
          `Unable to mark notification as read (id: ${notificationId})`,
          error
        )
        toast.error((t) => (
          <notificationsToastContent toast={t} systemNotification>
            {`Unable to mark notification as read (id: ${notificationId}). See console for details.`}
          </notificationsToastContent>
        ))
      }
    }
  }

  const getNotificationsCount = useCallback(
    async (notifyNewCount) => {
      try {
        LogDebug("GetNotificationsCount")

        let organizationList = organizations

        //If this is not a normal counter event, retrieve the organization list if there is none.
        if (notifyNewCount && !organizationList) {
          const list = await getOrganizations({
            variables: {
              pid,
            },
          })

          organizationList = list?.data?.organizations
          setOrganizations(organizationList)
        }

        if (organization_id && enterprise_id && !!organizationList) {
          const res = await noteApiCountPost(
            user.tokenNotification,
            enterprise_id,
            organizationList.map((x) => x.organization_id),
            pid,
            user.participant_alias,
            DateTime.fromISO(user.participant_create_date).toJSDate()
          )

          if (res) {
            if (
              notifyNewCount &&
              res?.numTotal !== notificationsCount?.numTotal &&
              res?.numTotal > 0
            ) {
              showInfo(
                "New notifications received, please open the Notifications Hub for more information"
              )
            }

            setNotificationsCount(res)
          }
          LogDebug("Notifications Count", res)
        } else {
          console.warn("No organization or enterprise specified")
        }
      } catch (error) {
        console.error("Unable to count notifications", error)
        setErrorState("Unable to count notifications. See console for details.")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      organizations,
      user.tokenNotification,
      user.participant_alias,
      user.participant_create_date,
      pid,
    ]
  )

  useEffect(() => {
    try {
      getOrganizations({
        variables: {
          pid,
        },
      })
        .then((organizationList) => {
          setOrganizations(organizationList?.data?.organizations)
        })
        .catch((error) => {
          LogError("Unable to retrieve organization list", error)
          setOrganizations(null)
        })
    } catch (error) {
      LogError("Unable to retrieve organization list", error)
      setOrganizations(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getNotificationsCount()
  }, [user.organization_id, user.enterprise_id, getNotificationsCount])

  useEffect(() => {
    if (notificationsCount !== null) {
      getNotificationsCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount])

  useEffect(() => {
    if (errorState) {
      showError()
    }
  }, [errorState, showError])

  useEffect(() => {
    if (!!user.notification_error) {
      showError(user.notification_error)
      user.setNotificationError(undefined)
    }
    if (!!user.notification_Information) {
      showInfo(user.notification_Information)
      user.setNotificationInformation(undefined)
    }
    if (!!user.notification_action) {
      setExternalAction(user.notification_action)
      user.setNotificationAction(undefined)
    }
  }, [showError, user])

  // SignalR
  useEffect(() => {
    if (!connection) {
      setupSignalRConnection()
    }

    // Monitor for SignalR errors
    if (hubError) {
      console.warn(
        "SignalR Warning: ",
        hubError,
        `\nConnection State: ${hubConnectionState}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, hubError])

  const DialogHeaderTemplate = (label, iconName) => {
    return {
      label: label,
      template: (item, options) => {
        return (
          /* custom element */
          <button
            type="button"
            className={`${noteStyle.noteMainTabHeader} ${
              options.active ? noteStyle.noteMainTabHeaderActive : ""
            }`}
            target={item.target}
            onClick={options.onClick}
          >
            <span
              className="material-icons"
              style={{ verticalAlign: "middle" }}
            >
              {iconName}
            </span>
            <span
              className={options.labelClassName}
              style={{ verticalAlign: "middle" }}
            >
              {item.label}
            </span>
          </button>
        )
      },
    }
  }

  const DialogHeader = () => {
    let menuItems = [DialogHeaderTemplate("Notifications", "notifications")]

    if (user.staff_role !== 9 && user.staff_role !== 10) {
      menuItems.push(DialogHeaderTemplate("Tasks", "task"))
    }

    return (
      <TabMenu
        model={menuItems}
        activeIndex={show === "tasks" ? 1 : 0}
        onTabChange={(e) => setShow(e.index === 0 ? "notifications" : "tasks")}
      />
    )
  }

  //Display variables:
  let icon = "notifications"
  if (mutedNotifications !== null && mutedNotifications.channelSignalR) {
    icon = "notifications_paused"
  }
  if (errorState !== null) {
    icon = "notifications_off"
  }

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <Dialog
        header={DialogHeader()}
        visible={show !== ""}
        onHide={() => {
          setShow("")
        }}
        style={{ width: "1100px", height: "1000px" }}
        contentStyle={{ padding: 0 }}
      >
        {show === "tasks" ? (
          <TasksForm
            users={taskUsers}
            onSetUsers={setTaskUsers}
            skipUserRefresh={skipTaskUsersRefresh}
            onSetSkipUserRefresh={setSkipTaskUsersRefresh}
            activeKey={tasksActiveKey}
            activeSubKey={tasksActiveSubKey}
            onShowError={showError}
          />
        ) : (
          <NotificationsForm
            ActiveKey={notificationsActiveKey}
            onResetActiveKey={() => {
              setNotificationsActiveKey("")
            }}
            organizations={organizations}
            isDisabledParticipant={isDisabledParticipant}
            onShowError={showError}
            onShowNotification={showInfo}
            onClearMuteSettings={() => {
              setMutedNotifications(null)
            }}
            onHandleActionURL={handleActionURL}
            onRefreshBellCounter={setRefreshCount}
            onSetMutedNotifications={setMutedNotifications}
          />
        )}
      </Dialog>
      <li
        className="top-icon"
        onClick={() => {
          handleButtonClick()
        }}
      >
        <span className="material-icons top-icon-span p-overlay-badge">
          {icon}
          {notificationsCount && notificationsCount.numTotal > 0 && (
            <Badge
              value={notificationsCount.numTotal}
              style={{ fontSize: 12, transform: "translate(80%,-40%)" }}
            />
          )}
        </span>
        <label className="top-menu-label">Notifications Hub</label>
      </li>
    </>
  )
}
