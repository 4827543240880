import { useEffect, useState, useContext } from "react"
import ReactQuill from "react-quill"
import { DateTime } from "luxon"

//PrimeReact
import { Accordion, AccordionTab } from "primereact/accordion"
import { ProgressSpinner } from "primereact/progressspinner"
import { Paginator } from "primereact/paginator"
import { Tooltip } from "primereact/tooltip"

//style
import taskStyle from "../../../styles/TasksForm.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//Helpers
import { LogDebug } from "../../../helpers/logger"

//Tasks modules
import {
  taskApiGetAssignedTasksPOST,
  taskApiMarkCompletePUT,
} from "../TasksApi"

let lastRefresh = {
  pending: new Date().getTime(),
  completed: new Date().getTime(),
}

export default function TaskAssigned(props) {
  const user = useContext(UserContext)
  const moment = require("moment")

  const [loading, setLoading] = useState(false)
  const [pendingPageSize, setPendingPageSize] = useState(10)
  const [pendingTasks, setPendingTasks] = useState(null)
  const [completedTasks, setCompletedTasks] = useState(null)
  const [completedPageSize, setCompletedPageSize] = useState(10)
  const [pendingCurrentPage, setPendingCurrentPage] = useState(1)
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1)
  const [activePanel, setActivePanel] = useState(0)

  useEffect(() => {
    if (!pendingTasks) {
      getAssignedTasks(1, pendingPageSize, "pending")
    } else if (!completedTasks) {
      getAssignedTasks(1, completedPageSize, "completed")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingTasks, completedTasks])

  useEffect(() => {
    setActivePanel(props.activeOptionPanel || 0)
  }, [props.activeOptionPanel])

  useEffect(() => {
    if (props.refreshTasks > lastRefresh["pending"]) {
      getAssignedTasks(1, pendingPageSize, "pending")
    }
    if (props.refreshTasks > lastRefresh["completed"]) {
      getAssignedTasks(1, completedPageSize, "complete")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refreshTasks])

  async function getAssignedTasks(page, pageSize, ...types) {
    setLoading(true)

    for (const type of types) {
      try {
        // Limit refreshes
        if (new Date().getTime() - lastRefresh[type] < 1000) continue
        lastRefresh[type] = new Date().getTime()

        const res = await taskApiGetAssignedTasksPOST(user.tokenTask, {
          groupId: "All",
          userId: user.participant_id,
          pageNum:
            page ||
            (props.accordionKey === "0"
              ? pendingCurrentPage
              : completedCurrentPage),
          pageSize: pageSize
            ? pageSize
            : type === "pending"
            ? pendingPageSize
            : completedPageSize,
          status: type,
        })

        if (res) {
          if (type === "pending") setPendingTasks(res)
          else setCompletedTasks(res)

          if (res.numTotal > 0) {
            props.loadUsers(res.items.map((x) => x.createdByUserId))
          }
        }

        LogDebug(`Created Tasks [${type}]`, res)
      } catch (error) {
        console.error(`Unable to list ${type} created tasks`, error)
        props.onShowError("Unable to list tasks. See console for details.")
      }
    }
    setLoading(false)
  }

  const onPendingPageChange = (event) => {
    if (pendingPageSize !== event.rows) {
      setPendingPageSize(event.rows)
    }
    setPendingCurrentPage(event.page + 1)

    getAssignedTasks(event.page + 1, event.rows, "pending")
  }

  const onCompletedPageChange = (event) => {
    if (completedPageSize !== event.rows) {
      setCompletedPageSize(event.rows)
    }
    setCompletedCurrentPage(event.page + 1)

    getAssignedTasks(event.page + 1, event.rows, "complete")
  }

  //Convert to SOAR context
  function GetUserById(id) {
    let usr = { name2: "System" }

    if (id !== "0") {
      usr = props.getUser(id)
    }

    return <>{usr ? usr.name2 : id}</>
  }

  async function markTaskAsDone(taskId) {
    setLoading(true)
    try {
      await taskApiMarkCompletePUT(user.tokenTask, taskId)
      props.onRefreshTasks(new Date().getTime())
      LogDebug(`Task marked as complete (ID: ${taskId})`)
    } catch (error) {
      console.error(`Unable to mark task as complete (id: ${taskId})`, error)
      props.onShowError(
        `Unable to mark task as complete (id: ${taskId}). See console for details.`
      )
    }
    setLoading(false)
  }

  function getLocalDateTime(utcDateString) {
    return DateTime.fromISO(utcDateString, { zone: "utc" })
      .toLocal()
      .toFormat("MM/dd/yyyy HH:mm")
  }

  function TaskDisplayPanel(task) {
    let thisTask = task.task

    const baseClassName =
      thisTask?.completedOn === null
        ? taskStyle.taskGridContainer
        : taskStyle.taskGridContainerCompleted
    const overdue =
      moment.utc(thisTask?.dueOn) < moment.utc() && !thisTask?.compledOn
        ? taskStyle.taskGridOverDue
        : ""

    return (
      <div className={`${baseClassName} ${overdue}`} id={thisTask.id}>
        <Tooltip target=".tt-tooltip" />
        <div className={taskStyle.taskGridItemTitle}>
          <span className={taskStyle.taskGridDescription}>Title: </span>{" "}
          {thisTask.title}
        </div>
        <div className={taskStyle.taskGridItemCreated}>
          <span className={taskStyle.taskGridDescription}>Created: </span>{" "}
          {getLocalDateTime(thisTask.createdAt)}
        </div>
        <div className={taskStyle.taskGridItemAssignedBy}>
          <span className={taskStyle.taskGridDescription}>Assigned By: </span>{" "}
          {GetUserById(thisTask.createdByUserId)}
        </div>
        <div className={taskStyle.taskGridItemDue}>
          <span className={taskStyle.taskGridDescription}>Item due: </span>{" "}
          {getLocalDateTime(thisTask.dueOn)}
        </div>
        {thisTask.completedOn !== null ? (
          <div className={taskStyle.taskGridItemDone}>
            <span className={taskStyle.taskGridDescription}>Completed: </span>{" "}
            {getLocalDateTime(thisTask.completedOn)}
          </div>
        ) : null}
        <div className={taskStyle.taskGridItemNotes}>
          <Accordion>
            <AccordionTab header="Notes" headerStyle={{ padding: 0 }}>
              <ReactQuill
                theme="bubble"
                value={thisTask.description}
                readOnly={true}
                style={{ wordBreak: "break-all" }}
              />
            </AccordionTab>
          </Accordion>
        </div>
        {thisTask?.completedOn === null && !thisTask?.systemManaged ? (
          <div className={taskStyle.taskGridItemFunction}>
            <button
              type="button"
              className="btn"
              style={{
                float: "right",
                marginRight: 3,
                width: 40,
                height: 33,
                padding: "4px 9px",
              }}
              onClick={(e) => {
                markTaskAsDone(thisTask.id)
              }}
            >
              <span className={`material-icons`}> {"done"} </span>
            </button>
          </div>
        ) : null}
        {thisTask?.systemManaged ? (
          <div
            className={`${taskStyle.taskGridItemFunction} tt-tooltip`}
            data-pr-tooltip="System managed task"
          >
            <span className={`material-icons`}>vpn_lock</span>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div>
      {loading || props.parentLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <Accordion
            activeIndex={activePanel}
            onTabChange={(e) => {
              setActivePanel(e.index)
            }}
            style={{ width: "100%" }}
          >
            <AccordionTab
              header={`Pending tasks [${
                pendingTasks ? pendingTasks.items.length : 0
              }]`}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexDirection: "column", width: "100%" }}>
                  {pendingTasks
                    ? pendingTasks.items.map((x) => {
                        return <TaskDisplayPanel task={x} key={x.id} />
                      })
                    : null}
                </div>
              </div>
              {pendingTasks ? (
                <Paginator
                  first={pendingTasks.pageSize * (pendingTasks.pageNum - 1)}
                  rows={pendingTasks.pageSize}
                  totalRecords={pendingTasks.numTotal}
                  onPageChange={onPendingPageChange}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                />
              ) : null}
            </AccordionTab>
            <AccordionTab header="Completed tasks">
              <div style={{ flexDirection: "column", width: "100%" }}>
                {completedTasks
                  ? completedTasks.items.map((x) => {
                      return <TaskDisplayPanel task={x} key={x.id} />
                    })
                  : null}
              </div>
              {completedTasks ? (
                <Paginator
                  first={completedTasks.pageSize * (completedTasks.pageNum - 1)}
                  rows={completedTasks.pageSize}
                  totalRecords={completedTasks.numTotal}
                  onPageChange={onCompletedPageChange}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                />
              ) : null}
            </AccordionTab>
          </Accordion>
        </div>
      )}
    </div>
  )
}
