import { ProgressSpinner } from "primereact/progressspinner"
import styles from "../../styles/Loader.module.css"
import { gql } from "@apollo/client"
import moment from "moment"
import { DateTime } from "luxon"

export const staffNameInitials = (name) => {
    return name.first.charAt(0).toUpperCase() + name.last.charAt(0).toUpperCase()
}

export const getActualUTCTime = () => {
    return DateTime.utc().toISO()
}

export const sortListByDate = (list, dateField) => {
    list.sort((a, b) => {
        let dateA = DateTime.fromISO(a[dateField])
        let dateB = DateTime.fromISO(b[dateField])
        return dateB - dateA
    })
}

export function LoaderMedium() {
    return (
        <div className={styles.wrapperMed}>
            <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                animationDuration=".4s"
            />
        </div>
    )
}

export const findName = (id, list) => {
    const staff = list.find((staff) => staff.staff_id === id)
    return staff ? { first: staff.staff_name_first, last: staff.staff_name_last, full_name: staff.staff_full_name } : {
        first: "", last: "", full_name: ""
    }
}

export const getTimeAgo = (date) => {
    if (date != null && date !== "0001-01-01T00:00:00") {
        return moment.utc(date).local().fromNow()
    }
    return null
}

export const groupMessagesByDate = (messages) => {
    let groupedMessages = [];
    let lastFromId = null;
    let lastKey = null;
    let lastGroupIndex = -1;

    messages.forEach(message => {
        const relativeTime = moment(message.dateUpdated).fromNow();
        const key = `${relativeTime}_${message.fromId}`;
        if (key === lastKey && lastFromId === message.fromId) {
            groupedMessages[lastGroupIndex].messages.push(message);
        } else {
            groupedMessages.push({
                key: key,
                messages: [message]
            });
            lastGroupIndex++;
        }
        lastFromId = message.fromId;
        lastKey = key;
    });
    return groupedMessages;
}

export const GET_STAFF_NAMES = gql`
  query getStaffNamesByIds($staff_id: ID!, $organization_id: ID!, $staff_ids: [ID!]!) {
    getStaffNamesByIds(staff_id:$staff_id, organization_id:$organization_id, staff_ids: $staff_ids) {
        data
    }
  }
`