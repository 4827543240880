const ROUTES = {
  DEMO_PAGE: "/demo-page",
  DENIED: "/denied",
  FORM_PAGE: "/form/:idTemplate/:formPage",
  HOMEPAGE: "/",

  DEMO_PHONE_PAGE: "/demo-phone-page",
  ACTIVITY_LOG_CONTACT_LOG: "/activity-log/contact-log",
  ACTIVITY_LOG_HARM_REDUCTION: "/activity-log/harm-reduction",
  ACTIVITY_LOG_VOLUNTEER: "/activity-log/volunteer",
  ACTIVITY_LOG_GENERAL: "/activity-log/general",
  BLOCK_ADD: "/block/add",
  BLOCK_CLONE: "/block/customize/:idBlock",
  FORM_EDIT: "/form/:idTemplate/:formPage/:idStamp",
  FORM_VIEW_MODE: "/form/view/:idTemplate/:idStamp",
  GROUP_SESSION_LIST: "/group-session",
  LIBRARY_ADD: "/library/add",
  LIBRARY_CLONE: "/library/customize/:idField",
  PARTICIPANT_ADD: "/participant/add",
  PARTICIPANT_CUSTOM_DATA_FORMS: "/participant/custom-data-forms",
  PARTICIPANT_DISCHARGE: "/participant/discharge",
  PARTICIPANT_ENGAGEMENTS: "/participant/engagements",
  PARTICIPANT_ENGAGED_SERVICES: "/participant/engaged-services",
  PARTICIPANT_SERVICE_EPISODES: "/participant/service-episodes",
  PARTICIPANT_GPRA_FORMS: "/participant/gpra-forms",
  PARTICIPANT_LIST: "/participant/list",
  PARTICIPANT_MANAGE_SERVICES: "/form/manage_services/1/",
  PARTICIPANT_MYPARTICIPANTS: "/my-participants",
  PARTICIPANT_PROFILE: "/participant/profile",
  PARTICIPANT_PROFILE_ACCOUNT_EDIT: "/participant/account",
  PARTICIPANT_RECOVERY_CAPITAL: "/participant/recovery-capital",
  PARTICIPANT_RECOVERY_PLAN: "/participant/recovery-plan",
  PARTICIPANT_RECOVERY_PLAN_VIEW:
    "/participant/recovery-plan/view/:organization_id/:staff_id",
  PARTICIPANT_DOCUMENTS: "/participant/documents",
  PARTICIPANT_REFERRALS: "/participant/referrals",
  PARTICIPANT_REFERRALS_DETAIL: "/participant/referrals/detail/:id",
  PARTICIPANT_SETUP: "/participant/setup",
  PARTICIPANT_TELERECOVERY_SERVICES: "/participant/telerecovery-services",
  PARTNER_ENTITY_PROFILE: "/partner/profile",
  PARTNER_ENTITY_STAFF: "/partner/staff",
  PROFILE_EDIT: "/profile/edit",
  PROGRAMS_ASSIGN: "/program/assign/:program_identity_id",
  PROGRAMS_LIST: "/program/list",
  PROGRAM_EDIT: "/program/edit/:program_identity_id",
  REFERRALS_LIST: "/referrals/list",
  STAFF_LIST: "/staff/list",
  SUPERVISION_PLAN_LIST: "/supervision-plan/list",
  SUPERVISION_SESSION_LIST: "/supervision-session/list",
  TEMPLATE_ADD: "/template/add",
  TEMPLATE_CLONE: "/template/customize/:idTemplate",
  USER_ADD: "/user/add",
  USER_ADD_EXISTING: "/user/include",
  USER_EDIT: "/user/edit/:idUser",
  USER_LIST: "/user/list",
  USER_ASSIGN: "/user/assign/:idStaff",
  PROGRAM_ADD: "/program/add",
  LOCATION_ADD: "/location/add",
  LOCATION_EDIT: "/location/edit/:idLocation",
  LOCATIONS_LIST: "/location/list",

  PARTICIPANT_CONTACT_LOG: "/participant/contact-log",
  PARTICIPANT_GENERAL_ACTIVITY_LOG: "/participant/general-activity-log",
  PARTICIPANT_HARM_REDUCTION_ACTIVITY_LOG:
    "/participant/harm-reduction-activity-log",
  PARTICIPANT_HEP_A_FORM: "/participant/hep-a-form",
  PARTICIPANT_HEP_C_FORM: "/participant/hep-c-form",
  PARTICIPANT_REAL_D_FORM: "/participant/real-d-form",
  PARTICIPANT_VOLUNTEER_ACTIVITY_LOG: "/participant/volunteer-activity-log",
  PARTICIPANT_APPOINTMENTS: "/participant/appointments",
  PARTICIPANT_NOTIFICATIONS: "/participant/notifications",
  PARTICIPANT_MY_ACCOUNT: "/participant/my-account",
  PARTICIPANT_RECOVERY_CAPITAL_ASSESMENT:
    "/participant/recovery-capital-assesment",
  PARTICIPANT_CUSTOM_FORMS: "/participant/custom-forms",
}

export default ROUTES
